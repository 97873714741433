/* header */
header.sticky {background-color: #fff; position: fixed;-webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.15);box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.15);}
/* home banner */
.home-banner {background: url('../../images/banner-bg.png') 100% -50px no-repeat; background-size: 50%; min-height: 110vh;}
.home-banner h1{line-height: 55px;}
/* story section */
.story-section {background: url('../../images/bg-1.png') 100% 0 no-repeat; background-size: 100%; min-height: 100vh;}
/* how it works */
.how-it-works-section {padding: 75px 0 ;}
.how-it-works-section .line {padding-top: 250px;}
.how-it-works-section .container {max-width: 80%;}
.step-all {margin-top: -100px;}
/* features  */
.fetures-section  {background: url('../../images/bg-2.png') 100% 0 no-repeat; padding: 70px 0; background-size: 100%; min-height: 100vh;}
.fetures-section .box {background-color: rgba(255, 255, 255, .15); border-radius: 10px;}
/* download */
.download-section  { padding: 70px 0; }
.download-section .bg {background: url('../../images/bg-3.png') 100% 0 no-repeat;background-size: 100%; min-height: 85vh;padding: 70px}
.react-tabs__tab-list{border:0; border-radius: 30px;}
.react-tabs__tab {width: 100px; text-align: center; border:solid 1px #fff!important; color: #fff;}
.react-tabs__tab:focus:after{display: none;}
.react-tabs__tab:first-child{border-radius: 20px 0 0 20px;}
.react-tabs__tab:last-child{border-radius: 0 20px  20px 0;}
/* faqs */
.faqs-section {padding: 0px 0 50px;}
.accordion {border:0!important}
.accordion__button {border:0!important; background: #fff!important; color:rgb(18, 101, 255)!important; position: relative; padding: 18px 5px!important;}
.accordion__panel {padding: 20px 5px!important}
.accordion__button:before{content: "+"!important; border:0!important; margin-right: 10px!important; transform: rotate(0)!important; color:rgb(18, 101, 255); font-size: 24px; right: 0; position: absolute;}
.accordion__button[aria-expanded=true]:before{content: "-"!important;}
/* get in touch */
.getintouch-section {background: rgb(68,114,216); background: linear-gradient(90deg, rgba(68,114,216,1) 24%, rgba(255,255,255,1) 24%); min-height: 80vh; margin: 50px 0 0; padding: 70px 0 ;}
/* footer */
footer {background-color: #111212!important; padding: 50px 0 25px;}
footer h3 {border-bottom: solid 1px #666; padding-bottom: 30px;}
footer ul li{padding: 10px 0;}
footer ul li a{color: #ccc;}

@media (max-width:1400px){
    .fetures-section .box {padding: 15px!important;}
}


@media(max-width:640px){
    .home-banner {padding-bottom: 40px;}
    .home-banner h1 {
        line-height: 35px;
    }
    .story-section {background-position: 20% 0; padding: 15px; background-size: cover; min-height: 70vh;}
    .how-it-works-section .line {padding-top: 0px; max-width: 150px; margin: 0 auto;}
    .step-all{margin-top: 0;}
    .fetures-section{background-size: cover; padding: 50px 15px;}
    .fetures-section p{font-size: 12px; line-height: 18px;}
    .fetures-section .box img{max-width: 50px;}
    .fetures-section .box.small img{max-width: 40px;}
    .fetures-section .box {
        padding: 10px !important; min-height: 230px;
    }
    .download-section .bg {background-size: cover; background-position: 70% 0; padding: 50px 15px;}
    .react-tabs__tab-list{border:0!important}
    .download-section .bg a {display: block; margin-bottom: 10px; width: 100%;}
    .getintouch-section {
        background: rgb(68, 114, 216);padding: 30px 15px;
        background: linear-gradient(90deg, rgba(68, 114, 216, 1) 10%, rgba(255, 255, 255, 1) 10%);
    }
}